define("megaportal-frontend-engine-timetable/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('timetable', {
      path: '/'
    }, function () {
      this.route('view', {
        path: ':currentDate/:currentView'
      });
      this.route('event', {
        path: 'event/:classNumber/:termCode/:classDate'
      });
    });
  });

  _exports.default = _default;
});