$(function () {
    var fn = function () {
        // Get height of fixed header and set as top margin of page content
        var fixedHeaderHeight = $(".is-fixed").height();
        $(".page__content").css("margin-top", fixedHeaderHeight + "px");
    };
    if (typeof window.Ember !== 'undefined') {
        window.UQComponents = window.UQComponents || [];
        window.UQComponents.push(fn);
    } else {
        fn();
    }
});