$(function () {
    var fn = function () {
        $('.left-off-canvas-toggle').click(function (e) {
            e.preventDefault();
            $('.inner-canvas-menu-wrap').animate({scrollTop: 0}, 0);
        });
    };
    if (typeof window.Ember !== 'undefined') {
        window.UQComponents = window.UQComponents || [];
        window.UQComponents.push(fn);
    } else {
        fn();
    }
});
