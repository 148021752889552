define("megaportal-frontend-engine-myprofile/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('login');
    this.route('staff');
    this.route('student');
    this.route('candidature'); // temporary

    this.route('identity-management', {
      path: '/manage'
    }, function () {
      this.route('personal-email');
      this.route('mobile-number');
      this.route('change-password');
      this.route('security-questions');
    });
  });

  _exports.default = _default;
});