define("ember-cli-head/templates/components/head-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/+au8C0g",
    "block": "{\"symbols\":[],\"statements\":[[4,\"in-element\",[[25,[\"headElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[7,\"meta\"],[11,\"name\",\"ember-cli-head-start\"],[11,\"content\",\"\"],[9],[10],[1,[23,\"head-content\"],false],[7,\"meta\"],[11,\"name\",\"ember-cli-head-end\"],[11,\"content\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-head/templates/components/head-layout.hbs"
    }
  });

  _exports.default = _default;
});